<template>
  <v-row justify="center">
    <v-dialog
      v-model="detailsDialogState"
      persistent
      max-width="600px"
    >
      <v-card v-if="log !== null">
        <v-card-title>
          <span class="headline">{{ log.action }} - {{ $t('message.details') }}</span>
        </v-card-title>
        <v-container>
          <v-card-text>
            <ul class="log-details">
              <li
                v-for="(item, itemIndex) in items"
                :key="itemIndex"
              >
                {{ item.type === 'document' ? $t('message.document') : $t('message.folder') }} : <span>{{ item.name }}</span> <span v-if="item.type === 'folder' && item.nbChildren > 0">(<strong>{{ item.nbChildren }} {{ $t('message.element') }}(s))</strong></span>
              </li>
            </ul>
          </v-card-text>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>

          <button type="button" @click="closeDetailsDialog()" class="v-btn v-btn--flat v-btn--text theme--light v-size--default blue--text text--darken-1">
            <span class="v-btn__content">{{ $t('message.close') }}</span>
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'DetailsDialog',
  data: () => ({
    items: []
  }),
  props: {
    log: Object,
    detailsDialogState: Boolean
  },
  created () {
    if (this.log !== null) {
      this.items = JSON.parse(this.log.items)
    }
  },
  methods: {
    closeDetailsDialog () {
      this.$emit('closeDetailsDialog', false)
    }
  }
}
</script>
