<template>
  <div id="content">
    <div id="loading" v-if="loading">
      <div class="dot-flashing"></div>
    </div>
    <div class="wrapper">
      <header class="justify-start">
        <h1>{{ $t('message.logs') }}</h1>
        <span class="mt-1 ml-3">{{ $t('message.last_12_months') }}</span>
      </header>

      <v-row>
        <v-col class="tags-search" cols="3" sm="3">
          <vue-tags-input
            v-model="tag"
            :tags="tags"
            @tags-changed="newTags => {
              tags = newTags
              search()
            }"
            :placeholder="$t('message.search')"
          />
        </v-col>

        <v-col
          cols="12"
          lg="2"
        >
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted1"
                :label="$t('message.date_from')"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                @blur="from = parseDate(computedDateFormatted1)"
                v-on="on"
                autocomplete="off"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="from"
              no-title
              @input="menu1 = false"
              :allowed-dates="val => handleAllowedDates(val)"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          lg="2"
        >
          <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted2"
                :label="$t('message.date_to')"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                @blur="to = parseDate(computedDateFormatted2)"
                v-on="on"
                autocomplete="off"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="to"
              no-title
              @input="menu2 = false"
              :allowed-dates="val => handleAllowedDates(val)"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          lg="4"
        >
          <button class="btn btn-sm btn-primary reset-filters" :title="$t('message.search_2')" @click="search()"><i class="fas fa-search"></i>{{ $t('message.search_2') }}</button>
          <button class="btn btn-sm btn-primary reset-filters" :title="$t('message.reset')" @click="reset()"><i class="fas fa-undo"></i>{{ $t('message.reset') }}</button>
        </v-col>
      </v-row>

      <div class="table-responsive">
        <table class="mdl-data-table mdl-js-data-table mdl-data-table--selectable mdl-table-custom sm-2 table-logs" >
          <thead>
          <tr>
            <th>{{ $t('message.date_hour') }}</th>
            <th>{{ $t('message.action') }}</th>
            <th>{{ $t('message.user') }}</th>
            <th class="sm-hidden">{{ $t('message.ip') }}</th>
            <th class="sm-hidden">{{ $t('message.browser') }}</th>
            <th>{{ $t('message.details') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(log, logIndex) in logs"
            :key="logIndex"
          >
            <td data-label="Date :">
              <span class="font-weight-bold">{{ formatDate(log.createdAt) }}</span>
            </td>
            <td data-label="Action :" :title=log.action>
              {{ truncate(log.action) }}
            </td>
            <td data-label="Action :">
              {{ log.user ? log.user.firstName + ' ' + log.user.lastName : '-' }}
            </td>
            <td data-label="Date :" class="sm-hidden">
              {{ log.ip }}
            </td>
            <td data-label="Date :" class="sm-hidden">
              {{ log.browser }}
            </td>
            <td data-label="Détails :">
              <ul class="log-details items" v-if="log.items.length">
                <li
                  v-for="(item, itemIndex) in JSON.parse(log.items).slice(0, 3)"
                  :key="itemIndex"
                >
                  <div v-if="!isCopyLog(log) && !isUpdateLog(log)">
                    {{ resolveItemType(item.type) }} : <span>{{ item.type === 'folder' ? replaceFolderSectionName(item.name) : item.name }}</span> <span v-if="item.type === 'folder' && item.nbChildren > 0">- (<strong>{{ item.nbChildren }} {{ $t('message.elements') }}</strong>)</span>
                  </div>
                  <div v-if="isSecretLog(log) && isUpdateLog(log)">
                    <span>{{ $t('message.type_secret') }} :  {{ resolveValue(item, 'name') }}</span><br>
                    <span>{{ $t('message.updated_fields') }} : {{ resolveValueSecretUpdate(JSON.parse(log.details)) }}</span>
                  </div>
                  <div v-if="isSecretLog(log) && isCopyLog(log)">
                    <span>{{ $t('message.type_secret') }} :  {{ resolveValue(JSON.parse(log.details), 'secret') }}</span><br>
                    <span>{{ $t('message.copied_fields') }} : {{ resolveFields(JSON.parse(log.details)) }}</span>
                  </div>
                </li>
              </ul>
              <ul class="log-details details" v-if="log.details.length">
                <li v-if="isDetailType('secret', log) && !isCopyLog(log) && !isUpdateLog(log)">
                  <span>{{ $t('message.type_secret') }} :  {{ resolveValue(JSON.parse(log.details), 'secret') }}</span>
                </li>
                <li v-if="isDetailType('document', log)">
                  <span>{{ resolveFields(JSON.parse(log.details))}}</span>
                </li>
                <li v-if="isDetailType('dossier', log)">
                  <span>{{ resolveValueFolder(JSON.parse(log.details))}}</span>
                </li>
                <li v-if="isDetailType('ancien nom', log)">
                  <span>{{ $t('message.old_name') }} : {{ resolveValue(JSON.parse(log.details), 'ancien nom') }}</span><br>
                  <span>{{ $t('message.new_name') }} : {{ resolveValue(JSON.parse(log.details), 'nouveau nom') }}</span>
                </li>
              </ul>
              <span v-if="!isSecretLog(log) && log.items.length && JSON.parse(log.items).length > 3" class="log-details-button blue--text" @click="openDetailsDialog(log)">{{ $t('message.see_more') }}</span>
              <span v-if="!log.items.length && !log.details.length">-</span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <DetailsDialog
      :key="Date.now()"
      :details-dialog-state="detailsDialogState"
      :log="detailedLog"
      @closeDetailsDialog="closeDetailsDialog($event)"
    ></DetailsDialog>

    <div id="bottom-marker"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import DefaultLayout from '../../layouts/DefaultLayout.vue'
import config from '../../config'
import DetailsDialog from '../../components/Dialog/Log/DetailsDialog'
import VueTagsInput from '@johmun/vue-tags-input'

export default {
  name: 'Logs',
  components: { DetailsDialog, VueTagsInput },
  data: () => ({
    loading: false,
    logs: [],
    nextPage: 1,
    detailedLog: null,
    detailsDialogState: false,
    from: null,
    to: null,
    menu1: false,
    menu2: false,
    tag: '',
    tags: []
  }),
  created () {
    this.$emit('update:layout', DefaultLayout)
    document.title = config.title + ' - ' + this.$t('message.logs')
    this.getUserLogs()

    window.removeEventListener('scroll', this.handleScroll)

    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll)
    }, 1000)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
    computedDateFormatted1: {
      get () {
        return this.formatPickerDate(this.from)
      },
      set (val) {
        return val
      }
    },
    computedDateFormatted2: {
      get () {
        return this.formatPickerDate(this.to)
      },
      set (val) {
        return val
      }
    },
    isHandlingLogScroll () {
      return this.$store.getters.isHandlingLogScroll
    }
  },
  watch: {
    from (val) {
      this.computedDateFormatted1 = this.formatPickerDate(this.from)

      this.search()
    },
    to (val) {
      this.computedDateFormatted2 = this.formatPickerDate(this.to)

      this.search()
    }
  },
  methods: {
    handleScroll () {
      const wrapperOffsetHeight = document.querySelector('.v-application--wrap').offsetHeight
      const bottomOfWindow = wrapperOffsetHeight - (document.documentElement.scrollTop + window.innerHeight) <= 200

      if (bottomOfWindow && !this.isHandlingLogScroll) {
        this.getUserLogs()
      }
    },
    getUserLogs () {
      const user = this.$store.getters.getUser

      if (!user) {
        return
      }

      const params = {
        user: user.id,
        page: this.nextPage
      }

      const currentVault = this.$store.getters.getCurrentVault

      if (currentVault) {
        params.vault = currentVault.id
      }

      if (this.from) {
        params.from = this.from
      }

      if (this.to) {
        params.to = this.to
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      this.$store.commit('setHandlingLogScroll', true)

      Vue.prototype.$http
        .get(config.apiUrl + '/logs', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const logs = response.data

            if (logs && logs.length) {
              this.logs.push(...logs)

              this.nextPage = this.nextPage + 1
            }
          }

          this.loading = false
          this.$store.commit('setHandlingLogScroll', false)
        })
        .catch(e => {
          this.loading = false
          this.$store.commit('setHandlingLogScroll', false)
        })
    },
    search () {
      const user = this.$store.getters.getUser

      if (!user) {
        return
      }

      const params = {
        user: user.id,
        page: 1
      }

      const currentVault = this.$store.getters.getCurrentVault

      if (currentVault) {
        params.vault = currentVault.id
      }

      if (this.from) {
        params.from = this.from
      }

      if (this.to) {
        params.to = this.to
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/logs', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            this.logs = response.data

            this.nextPage = 2
          }
        })
        .catch(e => {
        })
    },
    reset () {
      this.tag = ''
      this.tags = []
      this.from = null
      this.to = null

      this.search()
    },
    formatPickerDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    truncate (name) {
      if (name && name.length > 100) {
        return name.substring(0, 100) + '...'
      }

      return name
    },
    openDetailsDialog (log) {
      this.detailedLog = log
      this.detailsDialogState = true
    },
    closeDetailsDialog () {
      this.detailsDialogState = false
    },
    formatDate (currentDate) {
      const timestamp = Date.parse(currentDate)
      const date = new Date(timestamp)
      let day = date.getDate()
      let month = date.getMonth() + 1
      let hour = date.getHours()
      let min = date.getMinutes()

      day = (day < 10 ? '0' : '') + day
      month = (month < 10 ? '0' : '') + month
      hour = (hour < 10 ? '0' : '') + hour
      min = (min < 10 ? '0' : '') + min

      return day + '/' + month + '/' + date.getFullYear() + ' ' + hour + ':' + min
    },
    handleAllowedDates (val) {
      const date = new Date()
      const day = parseInt(val.split('-')[2], 10)
      const month = parseInt(val.split('-')[1], 10) - 1
      const year = parseInt(val.split('-')[0], 10)
      const currentVal = new Date(year, month, day)

      return currentVal <= date
    },
    isSecretLog (log) {
      if (!log.items.length) {
        return false
      }

      const items = JSON.parse(log.items)
      for (let i = 0; i < items.length; i++) {
        const currentItem = items[i]
        if (currentItem.type === 'secret') {
          return true
        }
      }

      return false
    },
    isUpdateLog (log) {
      return log.action.includes('Modification')
    },
    isCopyLog (log) {
      return log.action.includes('Copie') || log.action.includes('Copy')
    },
    isDetailType (type, log) {
      if (!log.details.length) {
        return false
      }

      const items = JSON.parse(log.details)
      for (let key in items) {
        if (key === type) {
          return true;
        }
      }

      return false
    },
    resolveValue(details, valueName) {
      if (details) {
        for (let key in details) {
          if (key === valueName) {
            return details[key]
          }
        }
      }
      return '';
    },
    resolveValueSecretUpdate(details) {
      if (details) {
        details.shift();
        return details.join(', ');
      }
      return '';
    },
    resolveValueFolder(details) {
      if (details) {
        for (let key in details) {
          if (key === 'dossier') {
            return this.replaceFolderSectionName(details[key]);
          }
        }
      }
      return ''
    },
    replaceFolderSectionName(path) {
      let paths = path.split('/')
      let section = this.$t('message.section_' + paths[0])
      if (section === 'message.section_' + paths[0]) {
        return path;
      }
      paths.shift()
      return section + '/' + paths.join('/');
    },
    resolveFields (details) {
      const infos = []
      for (let key in details) {
        const currentDetail = details[key]
        // Document details
        if (key === 'document' || key === 'dossier') {
          infos.push(currentDetail)
        }

        // Secret details
        if (key === 'champ') {
          if (currentDetail === 'name') {
            infos.push(this.$t('message.label_name'))
          }

          if (currentDetail === 'url') {
            infos.push(this.$t('message.label_url'))
          }

          if (currentDetail === 'login') {
            infos.push(this.$t('message.label_login'))
          }

          if (currentDetail === 'password') {
            infos.push(this.$t('message.label_password'))
          }

          if (currentDetail === 'comment') {
            infos.push(this.$t('message.label_comment'))
          }
        }
      }
      return infos.join(', ')
    },
    resolveItemType (type) {
      if (type === 'document') {
        return this.$t('message.type_document')
      }

      if (type === 'folder') {
        return this.$t('message.type_folder')
      }

      if (type === 'secret') {
        return this.$t('message.type_secret')
      }

      return '-'
    }
  }
}
</script>
